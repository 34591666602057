<template>
  <div class="interactive-map-view">
    <div class="interactive-map-view__wrapper">
      <Section v-if="$laptop">
        <OnMap :params="{sizeMap: {
                width: '100%',
                height: $mobile ? 'calc(100vh - 56px)' : '100vh'
                },}"
               :places="places"
               :location="$city.location"/>
        <transition name="fade">
          <Column class="navigation-map"
                  :class="full && $mobile ? 'navigation-map__full' : ''">
            <Column class="navigation-map__wrapper"
                    v-on:click.native.self="toggle">
              <div class="navigation-map__toggle"></div>
              <div class="navigation-map__checkboxes-holder">
                <div v-for="type in $types"
                     class="navigation-map__type"
                     v-on:click="setType(type.id)"
                     :key="type.id">
                  <Row align="center">
                    <img class="navigation-map__icon"
                         :src="src(type.id)"
                         alt>
                    <Row class="navigation-map__type-row">
                      <span class="navigation-map__type-string">
                        <span class="navigation-map__type-title">{{type.name}}</span>
                        <span class="navigation-map__type-count">{{count(type)}}</span>
                      </span>
                    </Row>
                  </Row>
                </div>
              </div>
              <component :is="'Row'"
                         class="navigation-map__buttons-holder"
                         justify="between">
                <Button v-if="$laptop"
                        color="main"
                        v-on:click.native="$openPopupAddObject">{{ $t('interactive_map.add_obj') }}
                </Button>
                <Button color="like-link"
                        :full="$mobile"
                        v-on:click.native="clear">{{ $t('interactive_map.clear') }}
                </Button>
              </component>
            </Column>
          </Column>
        </transition>
      </Section>
      <Section v-else-if="!$laptop && $showIfDefaultCity"
               :limiter="!$mobile">
        <FormationPortal :backgroundImage="$laptop ?
          require('@/assets/images/mobile.jpg') : ''">
          <template slot="formation-portal-title">
            <span class="formation-portal__title">{{ $t('home.app.p_1') }} <br>
              {{ $t('home.app.p_2') }}</span>
          </template>
          <component :is="$mobile ? 'Column' : 'Row'"
                     :full="$mobile"
                     :class="$mobile ? '' : '-m-15'">
            <a class="home-view__app-icon"
               :href="appStoreHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/appstore.svg')" alt/>
            </a>
            <a class="home-view__app-icon"
               :href="googlePlayHref">
              <img class="icon-app"
                   :src="require('@/assets/images/svg/googleplay.svg')" alt/>
            </a>
          </component>
        </FormationPortal>
      </Section>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InteractiveMapView',
  data() {
    return {
      places: [],
      type_id: null,
      full: false,
      appStoreHref: process.env.VUE_APP_DEFAULTH_MOBILE_APPLE_LINK,
      googlePlayHref: process.env.VUE_APP_DEFAULTH_MOBILE_ANDROID_LINK,
    };
  },
  mounted() {
    if (this.$mobile) {
      document.querySelector('.footer').style.display = 'none';
    }
    this.$places.data.forEach((place) => {
      // eslint-disable-next-line no-param-reassign
      place.icon = this.$types.find((item) => item.id === place.type_id)?.image;
      this.places = [...this.places, place];
    });
  },
  destroyed() {
    document.querySelector('.footer').style.display = 'block';
  },
  methods: {
    src(id) {
      return this.$types.find((item) => item.id === id)?.image;
    },
    toggle() {
      this.full = !this.full;
    },
    setType(id) {
      this.type_id = id;
    },
    clear() {
      this.type_id = null;
    },
    count(type) {
      return this.$places.data.filter((item) => item.type_id === type.id).length;
    },
  },
  watch: {
    type_id() {
      this.places = [];
      this.$nextTick(() => {
        if (this.type_id === null) {
          this.places = this.$places.data;
        } else {
          this.places = this.$places.data.filter((item) => item.type_id === this.type_id);
        }
      });
    },
  },
};
</script>
